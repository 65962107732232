//.........global dev.........
// const baseUrl_admin = 'https://dev.app.marx.lk/admin_global';
// const baseUrl_public = 'https://dev.app.marx.lk/global';
// const returnMarxUrl = 'https://dev.global.marxpay.com';

//.........global prod........
// const baseUrl_admin = 'https://app.marx.lk/admin_global';
// const baseUrl_public = 'https://app.marx.lk/global';
// const returnMarxUrl = 'https://global.marxpay.com';

//.........local dev.........
// const baseUrl_admin = 'https://dev.app.marx.lk/admin';
// const baseUrl_public = 'https://dev.app.marx.lk/api';
// const returnMarxUrl = 'https://dev.marxpay.com';

//.........local prod........
const baseUrl_admin = 'https://app.marx.lk/admin';
const baseUrl_public = 'https://app.marx.lk/api';
const returnMarxUrl = 'https://marxpay.com';

//.........localhost.........
// const baseUrl_admin = 'http://localhost:8082';
// const baseUrl_public = 'http://localhost:8081';
// const returnMarxUrl = 'https://dev.marxpay.com';


export {baseUrl_admin,baseUrl_public,returnMarxUrl};


// note : effect of merchant type
